@charset "utf-8";

/*---------------------------------
	bit Common CSS 2018.2
	CSS bit-naka Document 2019.10
	Last Update 2018.02.00 noname

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Abel&display=swap');

/*####################　共通設定　####################*/
*{
  box-sizing:border-box;
}

html {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 100%;
  clear: both;
}

body {
  line-height: 1.8;
  text-align: center;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  /* font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif; */
  /* font-family: helvetica,'arial black',arial,sans-serif; */
  font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  letter-spacing: 0.06em;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-weight: 500;
  /*font-weight: 500;  ※游明朝の時は400*/
  color: #333;

}

p,li {
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  color: #ffa000;
}

hr, .showBox {
  display: none;
}

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left;
}

#Main {
  min-height: 500px;
  _height: 500px;
}

#Footer {
  margin: 0 auto;
  text-align: left;
}

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both;
}

.telArea {
  a {
    display: block;
    width: 100%;
    text-decoration: none;
    .tel {
      padding-left: 33px;
      width: 205px;
      border-bottom: #ffa000 solid 1px;
      background-size: 22px auto;
      background-repeat: no-repeat;
      letter-spacing: 0.04em;
      p {
        margin: 0;
        font-size: 28px;
        font-family: 'Abel', sans-serif;
        font-weight: 400;
        line-height: 0.9em;
      }
    }
    span {
      font-size: 10px;
      letter-spacing: 0.04em;
      color: #888;
    }
  }
}

/*#################### PC ####################*/
@media print, screen and (min-width: 768px) {
  body {
    min-width: 1000px;
    font-size: 16px;
  }
  p {
    margin: 0 0 15px;
    line-height: 1.9;
    font-size: 16px;
    letter-spacing: 0.02em;
  }
  li {
    letter-spacing: 0.02em;
  }
  a {
    text-decoration: none;
    &[href^="tel"] {
      text-decoration: none;
      pointer-events: none;
      &:hover {
        cursor: text;
      }
    }
    &.textLink {
      display: inline-block;
      position: relative;
      text-decoration: none;
      &:after {
      position: absolute;
      content: '';
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0;
      background: #ffa000;
      transition: .3s;
      }
    &:hover {
      &:after {
        bottom: 2px;
        opacity: 1;
      }
    }
  }
  }
  .link_btn {
    a {
      &:hover {
      opacity: 0.6;
      }
    }
  }
  .click {
    cursor: pointer;
  }
  .pc_display,.disp_pc {
    display: block;
  }
  .sp_display, .sp_display_inline,.disp_sp {
    display: none;
  }
  /*####################　枠設定　####################*/
  #Header, #Container {
    min-width: 1000px;
  }
  .innerBasic{
    width:1000px;
    margin:0 auto;
  }
  #Main {
    min-height: 420px;
    _height: 420px;
  }
  aside {
    width: 250px;
    float: right;
    padding-top: 38px;
  }
  #Footer {
    min-width: 1000px;
  }
}

@media screen and (max-width: 767px) {
/*#################### SP ####################*/
  body {
    font-size: 16px;
  }
  p {
    margin: 0 0 15px;
    line-height: 1.8;
    font-size: 16px;
  }
  a {
    text-decoration: underline;
  }
  .pc_display,.disp_pc {
    display: none;
  }
  .sp_display,.disp_sp {
    display: block;
  }
  .sp_display_inline {
    display: inline;
  }
  /*####################　枠設定　####################*/
  #Container {
  }
  .innerBasic {
    margin: 0 15px;
  }

}
  /*####################　ヘッダ　####################*/
  #Header{
    position: relative;
    height: 178px;
    background-image: url(../img/header/header_top.png);
    background-repeat: repeat-x;
    background-position: center top;
    background-size: auto 27px;
    h1{
      position: absolute;
      top: 42px;
      left:0;
      right: 0;
      margin: auto;
      width: 226px;
      height: 124px;
      a{
        display: block;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-image: url(../img/header/logo.png);
        width: 226px;
        height: 124px;
        background-size: 226px auto;
        text-indent: -9999px;
      }
    }
  }

  /* PC */
@media print, screen and (min-width: 768px) {
  #Header {
    height: 203px;
    h1 {
      top:45px;
      width: 275px;
      height: 151px;
      a {
        width: 275px;
        height: 151px;
        background-size: 275px auto;
      }
    }
    #HedCallBox {
      position: absolute;
      width: 358px;
      height: 60px;
      top: 60px;
      right: 110px;
      background-repeat: no-repeat;
      background-position: left top;
      background-image: url(../img/header/tel_pc.png);
      background-size: 139px auto;
      .tel {
          margin-left: 150px;
          padding-left: 25px;
          background-image: url(../img/footer/icon_tel.png);
          background-position: 0px 0px;
          border: none;
        }
      a {
        padding-top: 15px;
        span {
          display: block;
          margin-left: 150px;
        }
      }

    }
  }

}


  /*####################　グローバルナビ　####################*/
  #MenuButton {
    cursor: pointer;
    position: fixed;
    z-index: 60;
    top: 35px;
    right: 15px;
    box-sizing: border-box;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../img/header/icon_01.png);
    background-size: 55px auto;
    line-height: 1;
    text-indent: -9999px;
    text-align: center;
    font-size: 15px;
    color: #333;
    &:hover {
      transition: 0.3s;
    }
  }
  .active #MenuButton {
    background-position: center center;
    background-image: url(../img/header/icon_02.png);
    background-size: 55px auto;
  }
  #HeaderMenu_sp {
    position: fixed;
    top: 0px;
    right : -100%;
    z-index: 50;
    width: calc(100% - 50px);
    height: 100%;
    padding-bottom: 20px;
    background-color: #fff7db;
    text-align: center;
    overflow-y: scroll;
    transition: all 0.3s;
    transform: translate(calc(100% - 50px));
    ul {
      margin: 105px 15px 30px 15px;
      background-image: url(../img/contents/line_dot.png);
      background-repeat: repeat-x;
      background-size: auto 4px;
      background-position: left top;
      a {
        text-decoration: none;
        color: #333;
      }
      li {
        a {
            position: relative;
            display: block;
            width: 100%;
            height: 60px;
            padding-left: 30px;
            line-height: 60px;
            &:after {
              content: "";
              position: absolute;
              top:22px;
              right:15px;
              width: 7px;
              height: 13px;
              background-repeat: no-repeat;
              background-image: url(../img/header/arrow_01.png);
              background-size: 7px auto;
            }
          }
        ul {
          margin: 0;
          padding: 15px 15px 2px;
          background: none;
          background-color: #faeab0;
          border-radius: 10px;
          li {
            background: none;
            margin-bottom: 13px;
            a {
              display: inline;
              padding-left: 25px;
              height: auto;
              font-size: 14px;
              line-height: 2;
              &:after {
                top:0px;
                left: 0;
                width: 17px;
                height: 17px;
                background-image: url(../img/contents/arrow_02.png);
                background-size: 17px auto;
              }
            }
          }
          }
        &.menu01 {
          padding-bottom: 24px;
          background-image: url(../img/header/icon_img01.png), url(../img/contents/line_dot.png), url(../img/header/wall_img.png);
          background-repeat: no-repeat, repeat-x, no-repeat;
          background-size: 26px auto, auto 4px, 13px auto;
          background-position: left 22px, left bottom, 32px 50px;
        }
        &.menu02 {
          background-image: url(../img/header/icon_img02.png), url(../img/contents/line_dot.png);
          background-repeat: no-repeat, repeat-x;
          background-size: 24px auto, auto 4px;
          background-position: left 18px, left bottom;
        }
        &.menu03 {
          background-image: url(../img/header/icon_img03.png), url(../img/contents/line_dot.png);
          background-repeat: no-repeat, repeat-x;
          background-size: 18px auto, auto 4px;
          background-position: left 16px, left bottom;
        }
        &.menu04 {
          background-image: url(../img/header/icon_img04.png), url(../img/contents/line_dot.png);
          background-repeat: no-repeat, repeat-x;
          background-size: 24px auto, auto 4px;
          background-position: left 20px, left bottom;
        }
      }
    }
    #CallBox {
      margin: 0 15px;
      margin-bottom: 28px;
      border-top: #ffa000 solid 2px;
      background-image: url(../img/header/tel_wall.png);
      background-repeat: no-repeat;
      background-size: 141px;
      background-position: center 13px;
      background-color: #fff;
      a {
        padding-top: 85px;
        padding-bottom: 20px;
        line-height: 1.2em;
        .tel {
          margin: 0 auto;
          padding-left: 33px;
          background-image: url(../img/footer/icon_tel.png);
          background-position: 4px 2px;
        }
      }
    }
  }
  .active #HeaderMenu_sp {
    top: 0px;
    right : 0px;
    transform: translate(0);
  }
  #Wall {
    display: none;
  }
  .active #Wall {
    position: fixed;
    z-index: 15;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .2);
    text-indent: -9999px;
  }

@media print, screen and (min-width: 768px) {
/*##### PC #####*/
  #MenuButton {
    top:65px;
    right: 30px;
    &:hover {
      opacity: 0.6;
    }
  }
  #HeaderMenu_sp {
    width: 355px;
    transform: translate(355px);
    ul {
      margin: 135px 30px 30px 30px;
      li {
        a {
          &:hover {
            color: #ffa000;
            transition: 0.3s;
          }
        }
      }
    }
    #CallBox {
      margin: 0 30px;
      background-image: url(../img/header/tel_pc.png);
      background-size: 139px;
      .tel {
        border: none;
      }
    }
  }
}


  /*####################　サブメニュー　####################*/
      #BlogNavi {
      margin-bottom: 70px;
      h2 {
        position: relative;
        padding-left: 44px;
        height: 43px;
        line-height: 42px;
        font-size: 18px;
        &:before {
        content: "";
        position: absolute;
        top:0px;
        left: 0px;
        background-repeat: no-repeat;
        }
      }
      ul {
        padding: 5px 20px;
        background-image: url(../img/contents/wall_02.png);
        background-position: left top;
        background-repeat: repeat;
        background-size: 345px auto;
        border-radius: 10px;
        li {
          font-size: 14px;
          background-image: url(../img/contents/line_dot.png), url(../img/contents/arrow_04.png);
          background-repeat: repeat-x, no-repeat;
          background-size: auto 4px, 7px auto;
          background-position: left bottom, right center;
          &:last-of-type {
          background-image: url(../img/contents/arrow_04.png);
          background-repeat: no-repeat;
          background-size: 7px auto;
          background-position: right center;
          }
          a {
            display: block;
            padding: 12px 16px 12px 0px;
            color: #000;
          }
        }
      }
    }

@media print, screen and (max-width: 767px) {
/*##### SP #####*/
  #BlogNavi {
    ul {
      li {
        &.current-cat {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media print, screen and (min-width: 768px) {
/*##### PC #####*/
  #BlogNavi {
    ul {
      li {
        a {
          text-decoration: none;
          &:hover {
            color: #ffa000;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

  /*####################　コンテンツ部　####################*/
  /*　メイン
  -------------------------------------*/
  #MainImg {
    margin: 0 auto;
  }
  #MainImgInner {
    position: relative;
    h2 {
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      margin:auto;
      width: 100px;
      height: 100px;

    }
  }

  #Main{
/* 診察表 */
        table.service {
          margin-bottom: 12px;
          width: 100%;
          tr {
            &:first-of-type {
              th {
                width: 10%;
              }
            }
          th {
            font-size: 14px;
            text-align: center;
            &.sun {
              color: #ff6666;
            }
            &.sat {
              color: #439ce7;
            }
          }
          td {
            padding-top: 2px;
            text-align: center;
            font-size: 16px;
            line-height: 38px;
            color: #f8697c;
            span {
              color: #888;
              font-size: 14px;
            }
          }
          th,td {
            border-bottom: #ffa000 solid 1px;
            &:nth-of-type(1) {
              padding-left: 5px;
              width: 120px;
              text-align: left;
              font-size: 16px;
              color: #333;
            }
          }
        }
        }
/* 記事一覧　*/
    ul.blogList {
      margin-bottom: 30px;
      background-image: url(../img/contents/line_dot.png);
      background-repeat: repeat-x;
      background-size: auto 4px;
      background-position: left top;
        li {
            //display: none;
            background-image: url(../img/contents/line_dot.png);
            background-repeat: repeat-x;
            background-size: auto 4px;
            background-position: left bottom;
            a {
              display: block;
              padding: 10px 0 14px;
              color: #333;
              p {
                position:relative;
                margin: 4px 0 0 0;
                padding-left: 25px;
                text-decoration: none;
                &:before {
                  content: "";
                  position: absolute;
                  top: 5px;
                  left: 0;
                  width: 18px;
                  height: 18px;
                  background-repeat: no-repeat;
                  background-image: url(../img/contents/arrow_02.png);
                  background-size: 18px auto;
                }
              }
            }
            span {
              display: inline-block;
              margin-right: 5px;
              padding: 0 6px;
              border-radius: 4px;
              color: #fff;
              font-weight: bold;
              font-size: 12px;
              line-height: 20px;
            }
             &:nth-of-type(1) {
              a {
                padding: 14px 0;
              }
             }

        .linkArea {
          padding: 15px 50px 15px 0;
          cursor: pointer;
          background-image: url(../img/contents/icon_open_blog.png);
          background-repeat: no-repeat;
          background-size: 30px auto;
          background-position: right 5px center;
          text-align: left;
          p {
            margin-bottom: 0;
            text-decoration: underline;
          }
          &.active {
            background-image: url(../img/contents/icon_close_blog.png);
            p {
              text-decoration: none;
            }
          }
        }
        .showBox {
          margin-top: -15px;
          padding-top: 10px;
          padding-bottom: 15px;
          .textBox {
            position: relative;
            padding: 20px;
            width: 100%;
            background-color: #fff7db;
            border-radius: 10px;
            &:before {
              content: "";
              position: absolute;
              top:-10px;
              left: 33px;
              width: 12px;
              height: 12px;
              background-repeat: no-repeat;
              background-image: url(../img/contents/wall_01.png);
              background-size: 12px auto;
            }
            p.linkBtn {
              margin-bottom: 0;
              text-align: right;
              a {
                display: inline-block;
                position: relative;
                padding-top: 0;
                padding-right: 30px;
                width: 125px;
                height: 40px;
                border-radius: 20px;
                background-color: #ffa000;
                font-size: 14px;
                letter-spacing: 0.15em;
                line-height: 41px;
                color: #fff;
                text-decoration: none;
                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 15px;
                  bottom: 0;
                  margin: auto;
                  width: 8px;
                  height: 12px;
                  background-repeat: no-repeat;
                  background-image: url(../img/contents/arrow_01.png);
                  background-size: 8px auto;
                }
              }
            }
          }
        }
     }
    }
    .moreText {
      position: relative;
      display: block;
      margin: 0 auto;
      border-radius: 20px;
      width: 180px;
      height: 40px;
      background-color: #ffa000;
      line-height: 41px;
      text-align: center;
      color: #fff;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        margin: auto;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../img/contents/arrow_03.png);
        background-size: 15px auto;
      }
    }

/* 記事詳細　*/
    #PageNation {
      margin: 0 auto;
      height: 105px;
      width: 315px;
      ul.link_btn {
        position: relative;
        width: 100%;
        li {
          position: absolute;
          top: 0;
          text-align: center;
          font-size: 14px;
          a {
            display: inline-block;
            height: 40px;
            background-color: #ffa000;
            border-radius: 20px;
            line-height: 40px;
            color: #fff;
            text-decoration: none;
          }
          &.prev {
            left:0;
            width: 84px;
            a {
              width: 84px;
              padding-left: 20px;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 16px;
                width: 16px;
                height: 12px;
                background-repeat: no-repeat;
                background-image: url(../img/contents/arrow_05.png);
                background-size: 16px auto;
              }
            }
          }
          &.next {
            right:0;
            width: 84px;
            a {
              width: 84px;
              padding-right: 20px;
              &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: 16px;
                width: 16px;
                height: 12px;
                background-repeat: no-repeat;
                background-image: url(../img/contents/arrow_06.png);
                background-size: 16px auto;
              }

            }
          }
          &.top {
            width: 115px;
            left: 0;
            right: 0;
            margin: auto;
            a {
              width: 115px;
            }
          }
        }
      }
    }
  }

@media print, screen and (min-width: 768px) {
  /*##### PC #####*/
  #MainImg {
    #MainImgInner {
          height: 270px;
        background-image: url(../img/contents/wall_dot.png), url(../img/contents/bg_cloud3.png);
        background-repeat: repeat, no-repeat;
        background-position: center center, center top;
        background-size: 45px auto, auto 90px;
        background-color: #fff7db;
    }
  }
  #Main {
    .contBox {
      .linkBtn {
        a {
          &:hover {
            opacity: 0.6;
            transition: 0.3s;
          }
        }
      }
    }
          table.service {
            tr{
                th {
                  padding-bottom: 10px;
                  font-size: 16px;
                }
                td {
                  padding-top: 6px;
                  padding-bottom: 4px;
                }
            }
          }
    ul.blogList {
      li {
        .linkArea {
          padding: 20px 50px 25px 0;
          background-position: right center;
          &:hover {
            p {
              color: #ffa000;
              transition: 0.3s;
            }
          }
          p {
            text-decoration: none;
          }
        }
        .showBox {
          margin-top: -25px;
          padding-bottom: 27px;
          .textBox {
            padding: 25px 30px 30px 30px;
            p {
              line-height: 1.8;
              margin-bottom: 5px;
            }
            p.linkBtn {
              a:hover {
                opacity: 0.6;
              }
            }
          }
        }
          &:nth-of-type(1) {
            .linkArea {
              padding: 25px 0 25px 0;
            }
          }
      }
    }
    #PageNation {
      width: 408px;
      height: 150px;
      ul.link_btn {
        li {
          a {
            &:hover {
              transition: .3s;
            }
          }
          &.top {
            width: 180px;
            a {
              width: 180px;
            }
          }
        }
      }
    }
    .moreText {
      &:hover {
        opacity: 0.6;
        transition: 0.3s;
      }
    }
  }
}


  /*####################　フッタ　####################*/
  #Footer{
    position: relative;
    padding-top: 45px;
    background-image: url(../img/contents/bg_cloud.png);
    background-repeat: repeat-x;
    background-size: auto 45px;
    background-position: center top;
    #FooterInfo {
      padding-top: 18px;
      height: 300px;
      background-image: url(../img/footer/wall.jpg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 373px auto;
      background-color: #fff7db;
      h2 {
        margin: 0 auto;
        margin-bottom: 8px;
        width: 158px;
        height: 87px;
        background-image: url(../img/header/logo.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 158px auto;
        text-indent: -9999px;
        a {
          display: block;
          width: 158px;
          height: 87px;
        }
      }
      p {
        text-align: center;
        font-size: 12px;
        line-height: 1.6;
        letter-spacing: 0.03em;
        color: #888;
      }
    }
    #FooterTel {
      position: fixed;
      display: none;
      bottom: 0;
      width: 100%;
      height:72px;
      border-top: #ffa000 solid 2px;
      background-color: #fff;
      a {
        position: relative;
        display: block;
        width: 100%;
        max-width: 375px;
        height: 100%;
        margin: 0 auto;
        text-decoration: none;
        background-image: url(../img/footer/tel_wall.png);
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 151px auto;
      .telBox {
        position: absolute;
        right: 5px;
        width: 210px;
        height: 72px;
        line-height: 1.1;
        .tel {
          padding-top: 13px;
          background-image: url(../img/footer/icon_tel.png);
          background-position: 4px 13px;
        }

      }
    }
    }
    #CopyRight {
      padding-bottom: 72px;
      text-align: center;
      h2 {
        display: none;
      }
      background-color: #98c642;
      small,
      address {
        display: inline-block;
        font-family: 'Abel', sans-serif;
        font-weight: 400;
        font-size: 11px;
        color: #d8eb91;
      }
    }
  }

@media print, screen and (min-width: 768px) {
/*##### PC #####*/
  #Footer {
    padding-top: 65px;
    background-size: auto 90px;
    #FooterInfo {
      height: 366px;
      padding-top: 21px;
      background-image: url(../img/footer/wall_pc.jpg);
      background-size: 528px auto;
      h2 {
        width: 182px;
        height: 100px;
        margin-bottom: 8px;
        background-size: 182px auto;
      }
      p {
        font-size: 14px;
        line-height: 1.7;
      }
    }
    #FooterTel {
      display: none;
    }
    #CopyRight {
      padding-bottom: 0px;
      height: 40px;
      small, address {
        font-size: 14px;
        line-height: 40px;
      }
    }
  }
}

#Page404 {
  #Main {
    height: auto;
  }
  #ContBox01 {
    margin-top: 50px;
    text-align: center;
  }
}

/*end*/